<template>
  <top />
  <div class="main">
    <transaction-navbar :title="lang.v.PendingOrders" />
    <div class="list" v-loading="loading">
      <div v-for="item in OverRecord.transactionList" :key="item">
        <MyOverOrder :data="item" :timeLimit="OverRecord?.timeLimit" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue';
import Top from '@/components/Top/top.vue';
import TransactionNavbar from '@/components/TransactionNavbar/index.vue';
import MyOverOrder from '@/components/MyOverOrder/index.vue';
import dataCenter from '@/dataCenter';
import {
  fetchTransactionServePendingOrder,
  fetchTransactionServeTime,
  fetchTransactionServePayment,
} from '@/api/fetcher';
import dayjs from 'dayjs';
import { lang } from '@/lang';
const loading = ref(false);
//订单状态
const OrderStatusMap = [
  {
    id: 0,
    name: lang.v.establish,
    color: '#ED931E',
  },
  {
    id: 1,
    name: lang.v.approachingTimeout,
    color: '#ED931E',
  },
  {
    id: 2,
    name: lang.v.overtime,
    color: '#DC4343',
  },
  {
    id: 3,
    name: lang.v.cancel,
    color: '#858C9F',
  },
  {
    id: 4,
    name: lang.v.buyerConfirmed,
    color: '#ED931E',
  },
  {
    id: 5,
    name: lang.v.sellerConfirmed,
    color: '#ED931E',
  },
  {
    id: 6,
    name: lang.v.successfulTrade,
    color: '#858C9F',
  },
];

const OverRecord = reactive({
  page: 1,
  /** 列表 */
  transactionList: [],
  /** 单总数 */
  total: 0,
  /** 商品类型 1：买 2：卖 */
  type: '',
  /** 支付方式 */
  //订单状态
  status: '',
  payWay: null,
  // 支付方式名称
  payWayName: '',
  //超时时间
  timeLimit: 10,
});
// 获取时间限制
const getLimitTime = async () => {
  const data = await fetchTransactionServeTime();
  if (data) {
    dataCenter.payStatus.setTimeLimitMinutes(data);
    OverRecord.timeLimit = dataCenter.payStatus.timeLimitMinutes;
  }
};
//获取待处理数据
const getTransactionOverOrder = async () => {
  loading.value = true;
  const res = await fetchTransactionServePendingOrder({
    page: OverRecord.page,
    limit: 3,
    type: OverRecord.type,
    status: '0,1,4,5',
  }).finally(() => {
    loading.value = false;
  });
  if (res) {
    OverRecord.total = res.total;
    OverRecord.transactionList = res.list;
  }
  OverRecord.transactionList = (OverRecord.transactionList || []).reduce(
    (acc, item1) => {
      const match = (OverRecord.payWay || []).find(
        item2 => item2?.id == item1.payWay
      );
      if (match) {
        item1.payWayName = match.name;
      }
      item1.createTime = dayjs(item1.createTime).format('YYYY/MM/DD HH:mm:ss');
      acc.push(item1);
      return acc;
    },
    []
  );
  OrderStatusMapHandle(OverRecord.transactionList, OrderStatusMap);
};
// 获取支付方式
const getTransactionPayment = async () => {
  const res = await fetchTransactionServePayment();
  if (res) {
    OverRecord.payWay = res?.data?.data;
  }
};
//订单状态数据处理
const OrderStatusMapHandle = (list, Map) => {
  list = list.reduce((acc, item1) => {
    const match = Map.find(item2 => item2?.id == item1.status);
    if (match) {
      item1.statusName = match.name;
      item1.payWayColor = match.color;
    }
    acc.push(item1);
    return acc;
  }, []);
};
onMounted(() => {
  getTransactionPayment();
  getTransactionOverOrder();
  getLimitTime();
});
</script>

<style lang="less" scoped>
.main {
  padding: 0.14rem;
}
</style>
