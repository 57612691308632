<template>
  <div class="card">
    <div class="left-card">
      <div class="top-nicname">
        <img src="~@/assets/img/Ellipse.png" alt="" class="avatar" />
        <div class="nicname">{{ lang.v.anonymousUser }}</div>
      </div>
      <div class="order-id">
        {{ data?.id ? 'ID ' + data.id : '' }}
      </div>
      <div class="amount">
        <span>{{ lang.v.unit }}</span
        >&nbsp;{{ data?.commodityVO?.amount }}
      </div>
      <div class="count">
        <span>{{ buySell ? lang.v.demand : lang.v.sell }}</span
        >{{ data?.commodityVO?.integral }}{{ lang.v.integral }}
      </div>
      <div class="create">
        {{ data?.createTime }}
      </div>
    </div>
    <div class="right-card">
      <h3>
        <span
          :style="{
            color: buySell ? '#47C754' : '#DC4343',
          }"
        >{{ buySell ? lang.v.purchase : lang.v.sellOut }}</span
        >{{ lang.v.integral }}
      </h3>
      <div class="pay-way">
        {{ data?.payWayName }}
      </div>
      <div class="time">
        <img src="~@/assets/img/iconTime.png" class="icon-time" />
        <span class="text">{{ timeLimit }}{{ lang.v.minute }}</span>
      </div>
      <div class="status" :style="{ color: data.payWayColor }">
        {{ data.normal ? data?.statusName : lang.v.OrderErr }}
      </div>
      <div class="btn" @click="onGoDetail(data)">{{ lang.v.viewDetails }}</div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import dataCenter from '@/dataCenter';
import { computed, defineProps, toRefs } from 'vue';
import { lang } from '@/lang';
const router = useRouter();
const props = defineProps({
  data: {
    default: {},
  },
  timeLimit: {
    type: Number,
    default: 0,
  },
});
const { data, timeLimit } = toRefs(props);
//跳转详情
const onGoDetail = item => {
  dataCenter.payStatus.setDataPayStatus(item);
  router.push({
    path: '/myOrderDetail',
    query: {
      id: data.value?.id,
      PeddingOrder: 2,
      type: data.value?.commodityVO?.type,
    },
  });
};

const buySell = computed(() => {
  if (data.commodityVO?.type == 1) {
    if (data?.buyUser == JSON.parse(localStorage.getItem('userInfo'))?.userId) {
      return true;
    } else {
      return false;
    }
  } else {
    if (data?.buyUser == JSON.parse(localStorage.getItem('userInfo'))?.userId) {
      return true;
    } else {
      return false;
    }
  }
});
</script>

<style lang="less" scoped>
.card {
  background: #fff;
  border-radius: 0.08rem;
  margin-bottom: 0.1rem;
  padding: 0.12rem;
  display: flex;
  justify-content: space-between;
  h3 {
    font-size: 0.14rem;
    font-weight: 600;
    margin-bottom: 0.07rem;
  }
  .order-id {
    margin-top: 0.07rem;
    font-size: 0.12rem;
    color: #858c9f;
    height: 0.18rem;
    margin-bottom: 0.06rem;
  }
  .amount {
    font-size: 0.22rem;
    color: #222;
    line-height: 0.32rem;
    margin-bottom: 0.05rem;
    span {
      font-size: 0.12rem;
    }
  }
  .count {
    font-size: 0.12rem;
    margin-bottom: 0.2rem;
    span {
      color: #858c9f;
    }
  }
  .create {
    color: #858c9f;
    font-size: 0.12rem;
    line-height: 0.18rem;
    margin-bottom: 0.04rem;
  }
  .right-card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .pay-way {
      height: 0.16rem;
      margin-bottom: 0.08rem;
      padding-right: 0.04rem;
      font-weight: 400;
      font-size: 0.11rem;
      color: #858c9f;
      border-radius: 0.01rem;
      border-right: 0.03rem solid #67d234;
    }
    .time {
      margin-bottom: 0.1rem;
      .icon-time {
        width: 0.12rem;
        height: 0.12rem;
      }
      .text {
        padding: 0.02rem;
        font-weight: 400;
        font-size: 0.12rem;
        color: #858c9f;
        line-height: 0.16rem;
        border-bottom: 0.01rem #858c9f dashed;
      }
    }
    .status {
      height: 0.12rem;
      margin-bottom: 0.2rem;
      color: #858c9f;
    }
    .btn {
      width: 0.92rem;
      height: 0.34rem;
      background: #ed931e;
      border-radius: 0.06rem;
      font-size: 0.12rem;
      line-height: 0.34rem;
      color: #ffffff;
      text-align: center;
    }
  }
}
.top-nicname {
  display: flex;
  align-items: center;
  .nicname {
    height: 0.2rem;
    font-weight: 400;
    font-size: 0.14rem;
    color: #000000;
    line-height: 0.16rem;
  }
  .avatar {
    margin-right: 0.05rem;
    width: 0.22rem;
    height: 0.22rem;
  }
}
</style>
